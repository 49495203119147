
import { getOssUrl } from '@/libs/utils';
import { ONLINE_HOST, OSS_STORAGE_DIR } from '@/store/constants';
import { copySelectTextContents } from '@mobro/libs';
import { Iobject } from '@mobro/libs/es/type';
import { Message } from 'element-ui';
import { Component, Vue } from 'vue-property-decorator';

@Component({})
export default class Upload extends Vue {
    private readonly ONLINE_HOST = ONLINE_HOST;
    private formData: Iobject = {
        bucket: 'news-public',
        dir: OSS_STORAGE_DIR[0],
        useOriginName: false,
        useCover: false,
    };
    private fileList: Iobject[] = [];
    private ossStorageDirList = OSS_STORAGE_DIR;

    onChange(file: Iobject) {
        if (file.status === 'success' && file?.response?.data?.name) {
            file.name = file.response.data.name;
        }
    }

    onSuccess(response: Iobject, file: Iobject, fileList: Iobject[]) {
        this.fileList = fileList.map((file: Iobject) => {
            return {
                realUrl: file.url,
                name: file.name,
                url: file.url,
                success: 'success',
            };
        });
    }
    async onPreview(file: Iobject) {
        if (
            (file?.response && file?.response?.url && (await copySelectTextContents(file?.response?.url))) ||
            (file?.response?.data &&
                file?.response?.data?.nowFileName &&
                (await copySelectTextContents(
                    getOssUrl(
                        file?.response?.data?.dir ||
                            `/${file?.response?.data?.dir}/${file?.response?.data?.nowFileName}`,
                    ),
                )))
        ) {
            Message.success('图片链接已复制！');
        }
    }
}
